body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
::-webkit-scrollbar {
  display: none;
}

.new-card {
  width: 95%;
  border-radius: 1.5rem;
  gap: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  border: 1px solid lightgray;
}
.card {
  box-shadow: 0 1px 4px #D6D6D69E;
  padding: 10px;
  width: 95%;
  border-radius: 9px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
}

.counter-button {
  text-align: center;
  padding: 0;
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.2rem;
}

.counter-button:active {
  background-color: white;
  color: black;
}
