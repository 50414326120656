.topIconCard {
    min-height: 240px;
    background-color: #FF9A260D;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mainTextContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}
.mainHeading{
    font-weight: 700;
    font-size: 18px;
    margin: 0;
}
.mainSubHeading{
    font-weight: 400;
    font-size: 14px;
    color: #707070;
    margin: 6px 0 0 0;
}
.percentageIcon{
    width: 106px;
    height: 100px;
    transform: rotate(10deg);
}
.formContainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}
.mobileInput{
    border: none;
    padding: 12px;
    width: 65%;
    font-weight: 600;
}
.mobileInput:focus{
    outline: none;
}
.amountInput{
    border: 1px solid #535353;
    padding: 12px;
    font-weight: 600;
    margin-top: 6px;
    border-radius: 5px;
 }
.amountInput::-webkit-outer-spin-button,.mobileInput::-webkit-outer-spin-button,
.amountInput::-webkit-inner-spin-button,.mobileInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mobileInputContainer{
    display: flex;
    border: 1px solid #535353;
    justify-content: space-between;
    padding: 1px;
    margin-top: 8px;
}
.verifyOtpBtn{
    border: none;
    background-color: white;
    text-align: center;
    align-content: center;
    width: 30%;
    color: #6AC47F;
    font-weight: 700;
}
.verifyOtpBtn:disabled{
    color: grey;
}
.resendText{
    font-size: 11px;
    text-decoration: underline;
    color: black;
}
.lable{
    font-size: 12px;
    color: #242424;
    font-weight: 600;
}
.nameInput{
    font-weight: 600;
}
.nameInput:focus,.amountInput:focus{
    outline: none;
}
.submitBtn{
    width: 100%;
    align-self: center;
    padding: 10px;
    background-color: black;
    color: white;
    font-weight: 600;
    font-size: 16px;
    border-radius: 8px;
    margin-top: 24px;
    border: none;
    text-align: center;
}
.submitBtn:disabled {
    background-color: grey;
    opacity: 1;
}
.formItem{
    margin-top: 6px;
}
.submitConatiner{
    margin-top: 16px;  
    display: flex;
    flex-direction: column;
}
.formCard{
    box-shadow: 0px 1px 6px rgb(0 0 0 / 10%);
    width: 80%;
    display: flex;
    justify-content: center;
    padding: 20px;
    border: 1px solid black;
    z-index: 100;
    background: white;
    margin-top: -50px;
}
.topHeading{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-top: 0;
}
.arrow{
    width: 18px;
    height: 12px;
    margin-left: 6px;
}
